'use client';

import React, { ReactNode, useState } from 'react';

import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  from,
  HttpLink,
  split,
} from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { setContext } from '@apollo/client/link/context';
import { ErrorResponse, onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { useAuth0 } from '@auth0/auth0-react';
import { red60 } from '@carbon/colors';
import { CloseOutline } from '@carbon/icons-react';
import {
  Button,
  CodeSnippet,
  Link as CarbonLink,
  Stack,
  Tile,
} from '@carbon/react';
import { ErrorCode, User } from '@wastewizer/authz';
import { LocalStorageKey } from '@wastewizer/ui-constants';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { useReadLocalStorage } from 'usehooks-ts';

import styles from './ApolloProvider.module.scss';
import { typePolicies } from '../../typePolicies';

type WrappedApolloProviderProps = {
  children: ReactNode;
};

const WrappedApolloProvider: React.FunctionComponent<
  WrappedApolloProviderProps
> = ({ children }) => {
  const router = useRouter();
  const impersonation = useReadLocalStorage<User>(
    LocalStorageKey.IMPERSONATION,
  );
  const { getAccessTokenSilently } = useAuth0();
  const [error, setError] = useState<ErrorResponse | null>(null);

  const client = React.useMemo(() => {
    const authLink = setContext(async (_, { headers }) => {
      const token = await getAccessTokenSilently();

      return {
        headers: {
          ...headers,
          ...(token && { Authorization: `Bearer ${token}` }),
          ...(impersonation && {
            'X-Impersonate': JSON.stringify(impersonation),
          }),
        },
      };
    });

    const retryLink = new RetryLink();
    const errorLink = onError((errorResponse) => {
      if (errorResponse.graphQLErrors) {
        errorResponse.graphQLErrors.forEach((error) => {
          if (error.extensions.code === ErrorCode.NOT_AUTHORIZED) {
            return router.replace('/not-authorized');
          }
        });
      }

      setError(errorResponse);
    });

    const uri = '/graphql';
    const httpLink = new HttpLink({ uri });
    const batchHttpLink = new BatchHttpLink({
      uri,
      batchMax: 10,
      batchInterval: 20,
    });

    return new ApolloClient({
      link: from([
        authLink,
        retryLink,
        errorLink,
        split(
          (operation) => operation.getContext().batch === false,
          httpLink,
          batchHttpLink,
        ),
      ]),
      cache: new InMemoryCache({
        typePolicies,
      }),
      name: 'Scalable Systems Client Application',
      connectToDevTools: process.env.NEXT_PUBLIC_ENABLE_DEV_TOOLS === 'true',
    });
  }, [getAccessTokenSilently, impersonation, router]);

  return (
    <ApolloProvider client={client}>
      {error && (
        <Tile className={styles.error}>
          <Stack gap={6}>
            <h2>
              <CloseOutline size={24} color={red60} /> Something went wrong!
            </h2>
            <CodeSnippet type="multi">
              Operation: {error.operation.operationName}
              <br />
              {error.graphQLErrors?.map((e) => e.message).join('<br />')}
            </CodeSnippet>
            <p>
              <Button
                onClick={
                  // Attempt to recover by trying to re-render the segment
                  () => router.refresh()
                }
              >
                Try again
              </Button>
            </p>
            <p>
              <Link href="/">
                <CarbonLink>Return to the homepage</CarbonLink>
              </Link>
            </p>
          </Stack>
        </Tile>
      )}
      <div className={error ? styles.hasError : undefined}>{children}</div>
    </ApolloProvider>
  );
};

export default WrappedApolloProvider;
