import React, { FunctionComponent } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { useStatsigUser } from '@statsig/react-bindings';
import {
  User,
  isSuperUser,
  normalizeAccountRoles,
  normalizeAppRoles,
} from '@wastewizer/authz';
import { LocalStorageKey } from '@wastewizer/ui-constants';
import { useReadLocalStorage } from 'usehooks-ts';

import { UserTheme, WeightDisplay, WeightUnit } from '#generated-types';
import { useTrackTokenExchangeMutation } from './_generated';
import { UserContext } from '../../context/UserContext';
import { UserWithPreferences } from '../../types/UserWithPreferences';
import { getWeightLabel } from '../../utils/getWeightLabel';

type UserProviderProps = {
  children: React.ReactNode;
};

export const UserProvider: FunctionComponent<UserProviderProps> = ({
  children,
}) => {
  const { updateUserAsync } = useStatsigUser();

  const [user, setUser] = React.useState<UserWithPreferences>(null);
  const impersonation = useReadLocalStorage<User>(
    LocalStorageKey.IMPERSONATION,
  );
  const { getIdTokenClaims, user: auth0User } = useAuth0();
  const [trackTokenExchange] = useTrackTokenExchangeMutation();

  React.useEffect(() => {
    const getUser = async (): Promise<UserWithPreferences> => {
      const claims = await getIdTokenClaims();

      if (!auth0User || !claims) {
        // This just allows us to destructure the user when using the hook without destructure issues
        // happening before the user is populated by Auth0.  The AuthN component will protect us from
        // unauthorized use
        return {
          appRoles: [],
          accountRoles: [],
          preferences: {
            theme: UserTheme.Light,
            weightUnit: WeightUnit.ShortTon,
            weightLabel: getWeightLabel(WeightUnit.ShortTon),
            weightDisplay: WeightDisplay.Gross,
          },
        } as UserWithPreferences;
      }

      const namespace = 'https://client.wastewizer.com';

      const email = auth0User.email as string;
      const appRoles = normalizeAppRoles(claims[`${namespace}/roles`] || []);
      const accountRoles = normalizeAccountRoles(
        claims[`${namespace}/app_metadata`]?.accountRoles || [],
      );

      const defaultPreferences: UserWithPreferences['preferences'] = {
        theme: UserTheme.Light,
        weightUnit: WeightUnit.ShortTon,
        weightDisplay: WeightDisplay.Gross,
      };

      const userPreferences: UserWithPreferences['preferences'] =
        claims[`${namespace}/user_metadata`]?.preferences || {};

      const mergedPreferences: UserWithPreferences['preferences'] = {
        ...defaultPreferences,
        ...userPreferences,
        weightLabel: getWeightLabel(
          userPreferences.weightUnit || WeightUnit.ShortTon,
        ),
      };

      if (impersonation) {
        if (isSuperUser({ email, appRoles, accountRoles })) {
          return {
            ...impersonation,
            preferences: mergedPreferences,
          };
        }
      }

      // Track that the user is now logged in (token exchange)
      trackTokenExchange(); // fire and forget this one

      return {
        email,
        appRoles,
        accountRoles,
        preferences: mergedPreferences,
      };
    };

    getUser().then(async (user) => {
      if (user.email) {
        window.newrelic?.setCustomAttribute('user', user.email);
        window.newrelic?.setCustomAttribute('email', user.email);

        await updateUserAsync({
          userID: user.email,
          email: user.email,
          custom: {
            appRoles: user.appRoles,
          },
        });
      }

      setUser(user);
    });
  }, [getIdTokenClaims, /* trackTokenExchange, */ auth0User, impersonation]);

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
};
